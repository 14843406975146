import path from 'path';

import { GetServerSideProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { cacheControl } from '@hultafors/shared/helpers';

import { getApi } from '@hultafors/wibe/api';
import { createMeta, spacing } from '@hultafors/wibe/helpers';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/wibe/types';

// Needed for standalone build, for some reason
path.resolve('./next.config.js');

const CategoryGrid = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.CategoryGrid),
);
const ContentArea = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.ContentArea),
);
const BigPlug = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.BigPlug),
);
const ColorPlugs = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.ColorPlugs),
);
const Hero = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Hero),
);
const Page = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.Page),
);
const PagePlugs = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.PagePlugs),
);
const SimpleTextAndCta = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.SimpleTextAndCta,
  ),
);
const TitleAndTextBlock = dynamic(() =>
  import('@hultafors/wibe/components').then(
    (module) => module.TitleAndTextBlock,
  ),
);
const VisibleSeoBlock = dynamic(() =>
  import('@hultafors/wibe/components').then((module) => module.VisibleSeoBlock),
);

interface LandingPageProps extends PageProps {
  content: LandingPageFragment;
}

const LandingPage: NextPage<LandingPageProps> = ({ content, settings }) => {
  return (
    <Page
      metadata={createMeta(content.seo, settings?.language.lang)}
      testId="landing-page"
    >
      {content?.contentArea && <ContentArea content={content.contentArea} />}
      <Hero
        title={content.heroTop?.[0]?.title}
        label={content.heroTop?.[0]?.label}
        ingress={content.heroTop?.[0]?.description}
        image={content.heroTop?.[0]?.image}
        imageTablet={content.heroTop?.[0]?.imageTablet}
        imageMobile={content.heroTop?.[0]?.imageMobile}
        cta={[
          {
            id: 'heroTopCta1',
            label: content.heroTop?.[0]?.firstCtaText,
            url: content.heroTop?.[0]?.firstCtaLink,
          },
          {
            id: 'heroTopCta2',
            label: content.heroTop?.[0]?.secondCtaText,
            url: content.heroTop?.[0]?.secondCtaLink,
          },
        ]}
        wide
        priority
      />
      {!!content.categories?.length && (
        <CategoryGrid categories={content.categories} desktopQuad={false} />
      )}
      <TitleAndTextBlock
        title={content.contentAreaTitle}
        description={content.contentAreaDescription}
        linkText={content.contentAreaLinkText}
        linkUrl={content.contentAreaLinkUrl}
      />
      <BigPlug
        image={content.bigPlugImage}
        title={content.bigPlugTitle}
        description={content.bigPlugDescription}
        linkText={content.bigPlugCtaText}
        linkUrl={content.bigPlugCtaUrl}
      />
      <Hero
        title={content.heroMid?.[0]?.title}
        label={content.heroMid?.[0]?.label}
        ingress={content.heroMid?.[0]?.description}
        image={content.heroMid?.[0]?.image}
        imageTablet={content.heroMid?.[0]?.imageTablet}
        imageMobile={content.heroMid?.[0]?.imageMobile}
        cta={[
          {
            id: 'heroMidCta1',
            label: content.heroMid?.[0]?.firstCtaText,
            url: content.heroMid?.[0]?.firstCtaLink,
          },
          {
            id: 'heroMidCta1',
            label: content.heroMid?.[0]?.secondCtaText,
            url: content.heroMid?.[0]?.secondCtaLink,
          },
        ]}
        wide
      />
      <SimpleTextAndCta
        text={content.textBlock?.[0]?.text}
        cta={{
          label: content.textBlock?.[0]?.ctaLabel,
          link: content.textBlock?.[0]?.ctaLink,
        }}
      />
      <PagePlugs
        plugs={[...(content?.pagePlugs || [])]}
        withMargin
        style={{ marginBottom: spacing.xl, marginTop: 0 }}
      />
      <ColorPlugs plugs={[...(content?.colorPlugs || [])]} />
      <VisibleSeoBlock
        title={content.greyInfoBox?.[0]?.title}
        description={content.greyInfoBox?.[0]?.body}
      />
    </Page>
  );
};

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async ({
  res,
  req,
  locale,
}) => {
  try {
    const { settings, dato } = getApi(req, locale);

    const {
      data: { landingPage: content, ...global },
    } = await dato.getLandingPage();

    res.setHeader('Cache-Control', cacheControl.page);
    return {
      props: {
        content,
        settings,
        ...(global as GlobalFields),
      },
    };
  } catch (error) {
    return { notFound: true };
  }
};

export default LandingPage;
