import { MetaData } from '@hultafors/shared/types';

/**
 * @desc Generates OG tags object from Dato SEO object.
 * @param object Dato SEO object
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMeta = (seo: any, lang = 'en'): MetaData => {
  const title = createTitle(seo?.title);
  if (!seo) {
    return {
      description: '',
      lang,
      title,
    };
  }
  const tags: any = {
    description: seo.description ? seo.description : '',
    lang,
    title,
  };

  if (seo.image) {
    tags.image = seo.image;
    // tags.image.width = 310;
    // tags.image.height = 310;
  }

  return tags;
};

/**
 * @desc Generates OG tags object
 * @param string title
 * @param string description
 * @param string image url
 * @param string locale, defaulting to "en"
 * @return object
 */
export const createMetaManually = ({
  title,
  description,
  imageUrl,
  lang = 'en',
}: {
  title?: string;
  description?: string;
  imageUrl?: string;
  lang?: string;
}) => {
  const tags: any = {
    description: description ? description : '',
    lang,
    title: createTitle(title),
  };

  if (imageUrl) {
    tags.image = {
      url: imageUrl,
      // width: 310,
      // height: 310
    };
  }

  return tags;
};

export function createTitle(input = ''): string {
  if (input.includes('W.steps')) {
    return input;
  }
  return [input, 'W.steps'].filter(Boolean).join(' - ');
}
